import React from 'react';
import { Input } from '../../../shared/controls';
import { View } from 'react-native';
import tw from 'tailwind-react-native-classnames';
import { FormSettingsProps } from './types';

const SettingsForm = (props: FormSettingsProps) => {
  const { tenantDetails, onChangeAction } = props;
  return (
    <View style={tw.style('w-full')} >
      <View style={tw.style('mb-4 w-full')}>
        <Input 
          placeholder='First name' 
          label='First name' 
          name='first_name' 
          value={tenantDetails.first_name} 
          onChange={onChangeAction} 
        />
        <Input 
          placeholder='Last name' 
          label='Last name' 
          name='last_name' 
          value={tenantDetails.last_name} 
          onChange={onChangeAction} 
        />
        <Input 
          placeholder='Email' 
          label='Email' 
          name='email' 
          value={tenantDetails.email} 
          onChange={onChangeAction} 
          editable={false}
        />
        <Input 
          placeholder='Phone' 
          label='Phone' 
          name='contact_number' 
          value={tenantDetails.contact_number} 
          onChange={onChangeAction} 
        />
      </View>
    </View>
  );
};

export default SettingsForm;
