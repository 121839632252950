import React from 'react';
import { View } from 'react-native';
import tw from 'tailwind-react-native-classnames';
import { Input } from '../../../shared/controls';

const LoginForm = (props: any) => {
  const { loginData, onInputChange } = props;
  return (
    <View style={tw.style('items-center w-full')} >
      <Input 
        placeholder='Email' 
        name='email' 
        value={loginData.email} 
        onChange={onInputChange} 
      />
      <Input 
        placeholder='Password' 
        name='password' 
        value={loginData.password} 
        onChange={onInputChange}
        isSecure
      />
    </View>
  );
};

export default LoginForm;
