import React from 'react';
import { View, FlatList } from 'react-native';
import tw from 'tailwind-react-native-classnames';
import Tile from '../../../shared/controls/tile-v1';
import { tileContent } from './content';

const SectionDetails = () => {

  const renderItem = ({ item }: any) => (
    <View style={tw.style('mr-4')}>
      <Tile {...item} />
    </View>
  );

  return (
    <View style={tw.style('mx-4 mb-2 web:mr-0')}>
      <FlatList 
        horizontal
        data={tileContent}
        renderItem={renderItem}
        keyExtractor={item => item.title}
      />
    </View>
  );
};

export default SectionDetails;
