export const FETCH_TENANT_DETAILS = 'FETCH_TENANT_DETAILS';
export const LOADING_TENANT_DETAILS = 'LOADING_TENANT_DETAILS';
export const UPDATE_TENANT_DETAILS = 'UPDATE_TENANT_DETAILS';
export const ERROR_TENANT_DETAILS = 'ERROR_TENANT_DETAILS';

import { AppDispatch } from '../store';
import * as tenantDetailsCtrl from '../../controllers/tenantDetails.controller';
import { TenantDetails } from '../../types/tenantDetails';

export const actionGetTenantDetails = () => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: LOADING_TENANT_DETAILS });
      const data = await tenantDetailsCtrl.getTenantDetails();
      dispatch({
        type: FETCH_TENANT_DETAILS,
        payload: data
      });
    } catch (error: any) {
      dispatch({
        type: ERROR_TENANT_DETAILS,
        payload: error
      });
    };
  };
};

export const actionUpdateTenantDetails = (tenantDetails: TenantDetails) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: LOADING_TENANT_DETAILS });
      const response = await tenantDetailsCtrl.updateTenantDetails(tenantDetails);
      if (response.status === 'success') {
        dispatch({
          type: UPDATE_TENANT_DETAILS,
          payload: tenantDetails,
        });
      } else {
        dispatch({
          type: ERROR_TENANT_DETAILS,
          payload: ''
        });
      };
    } catch (error: any) {
      dispatch({
        type: ERROR_TENANT_DETAILS,
        payload: error
      });
    };
  };
};
