import React from 'react';
import { View, Text, TextInput } from 'react-native';
import { InputTextProps } from './types';
import tw from 'tailwind-react-native-classnames';

const InputText = (props: InputTextProps) => {
  const { 
    onChange, 
    value, 
    editable = true, 
    isSecure, 
    label, 
    name, 
    placeholder 
  } = props;
  return (
    <View style={tw.style('w-full py-1')}>
      { label && <Text>{label}</Text> }
      <TextInput
        style={tw.style('border rounded-md h-9 py-2 px-4 border-gray-300')}
        value={value}
        onChangeText={text => onChange(name, text)}
        placeholder={placeholder ? placeholder : ''}
        autoCapitalize='none'
        autoCompleteType='off'
        secureTextEntry={isSecure}
        editable={editable}
      />
    </View>
  );
};

export default InputText;
