import AsyncStorage from '@react-native-async-storage/async-storage';

export const storeValue = async (storageKey: string, value: string) => {
  try {
    await AsyncStorage.setItem(storageKey, value);
  } catch (error) {
    console.log('ERROR: ', error);
  };
};

export const removeValue = async (storageKey: string) => {
  try {
    await AsyncStorage.removeItem(storageKey);
  } catch(error) {
    console.log('ERROR: ', error);
  };
};

export const getValue = async (storageKey: string) => {
  try {
    const value = await AsyncStorage.getItem(storageKey);
    return value;
  } catch(error) {
    console.log('ERROR: ', error);
  };
};
