import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import Svg, { G, Circle } from 'react-native-svg';
import { DonutCharProps } from './types';

const DonutCharSvg = ({
  value = 150,
  textValue = 'Days Remaining',
  radius = 60,
  strokeWidth = 20,
  color = 'white',
  fontColor = 'white',
  maxValue = 300
}: DonutCharProps) => {
  if(isNaN(maxValue) || isNaN(value) || value > maxValue) return null;
  const halfCircle = radius + strokeWidth;
  const circleCircunference = 2 * Math.PI * radius;
  const newMaxValue = (100 * value) / maxValue;
  const strokeDashoffset = circleCircunference - (circleCircunference * newMaxValue) / 100;

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      height: radius * 2,
      width: radius * 2,
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    container2: {
      marginHorizontal: 25
    },
    container3: {
      textAlign: 'center',
      fontSize: 16,
      color: fontColor,
      fontWeight: '900'
    },
    container4: {
      textAlign: 'center',
      fontSize: 10,
      color: fontColor,
    }
  });

  return (
    <View>
      <Svg 
        width={radius * 2} 
        height={radius * 2} 
        viewBox={`0 0 ${halfCircle * 2} ${halfCircle * 2}`}
        style={[{
          transform: [{ rotate: "-90deg" }]
        }]}
      >
        <G>
          <Circle 
            cx='50%'
            cy='50%'
            stroke={color}
            strokeWidth={strokeWidth}
            r={radius}
            fill='transparent'
            strokeOpacity={0.2} 
          />
          <Circle 
            cx='50%'
            cy='50%'
            stroke={color}
            strokeWidth={strokeWidth}
            r={radius}
            fill='transparent'
            strokeDasharray={circleCircunference}
            strokeDashoffset={strokeDashoffset}
            // strokeLinecap='round'
          />
        </G>
      </Svg>
      <View style={styles.container}>
        <View style={styles.container2}>
          <Text style={styles.container3}>{`${value}`}</Text>
        </View>
        <View style={styles.container2}>
          <Text style={styles.container4}>{textValue}</Text>
        </View>
      </View>
    </View>
  );
};

export default DonutCharSvg;
