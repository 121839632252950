import React from 'react';
import Settings from '../../components/screens/settings';

const SettingsScreen = () => {
  return (
    <Settings />
  );
};

export default SettingsScreen;
