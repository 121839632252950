import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import 'react-native-gesture-handler';
import tw from 'tailwind-react-native-classnames';
import { AvatarSvg } from '../../../shared/svg/icons';
import { useNavigation } from '@react-navigation/core';

const Footer = () => {
  const { tenantDetails } = useSelector((appState: RootState) => appState.tenantDetailsState);
  const navigation = useNavigation();
  return (
    <TouchableOpacity 
      style={tw.style('absolute bottom-10 border-t w-full')} 
      onPress={() => navigation.navigate('Settings') } 
    >
      <View style={tw.style('flex-row items-center p-3 pl-4')}>
        <AvatarSvg />
        <View style={tw.style('ml-2')}>
          <Text style={tw.style('text-base font-semibold text-gray-500')}>{tenantDetails.first_name}</Text>
          <Text style={tw.style('text-xs text-gray-400')}>{tenantDetails?.email}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default Footer;
