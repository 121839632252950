import React from 'react';
import Login from '../../components/screens/login';

const LoginScreen = () => {
  return (
    <Login /> 
  );
};

export default LoginScreen;
