import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../redux/store';
import { View } from 'react-native';
import { actionFetchTenancy } from '../../../redux/tenancy/actions';
import { actionGetTenantDetails } from '../../../redux/tenant-details/actions';
import { actionFetchPrivateFiles } from '../../../redux/files/private/actions';
import { actionFetchSharedFiles } from '../../../redux/files/shared/actions';
import tw from 'tailwind-react-native-classnames';
import AppDrawerNavigation from '../../../navigation/app-drawer-navigation';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { NavigationContainer } from '@react-navigation/native';
import LogoSvg from '../../shared/svg/logo';
import sleep from '../../../lib/sleep';
import HeartbeatAnimation from '../../shared/animations/hearbeat';

const Loading = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { tenancy } = useSelector((appState: RootState) => appState.tenancyState);
  const { tenantDetails } = useSelector((appState: RootState) => appState.tenantDetailsState);

  useEffect(() => {
    const init = async () => {
      await dispatch(actionGetTenantDetails());
      await dispatch(actionFetchPrivateFiles('private'));
      await sleep(2500);
      setLoading(false);
    };
    init();
  }, []);

  useEffect(() => {
    const init = async () => {
      await dispatch(actionFetchSharedFiles(`unit/${tenancy.unit_id}/tenancy/${tenancy.tenancy_id}`));
    };
    init();
  }, [tenancy]);

  useEffect(() => {
    const init = async () => {
      await dispatch(actionFetchTenancy(tenantDetails.uuid));
    };
    init();
  }, [tenantDetails]);
  
  if (loading) return (
    <SafeAreaProvider style={tw.style('items-center justify-center', { backgroundColor: '#FBFAFF' })} >
      <HeartbeatAnimation>
        <LogoSvg  width={200} height={200} />
      </HeartbeatAnimation>
    </SafeAreaProvider>
  );
  return (
    <View style={tw.style('h-full', { backgroundColor: '#FBFAFF' })} >
      <NavigationContainer>
        <AppDrawerNavigation />
      </NavigationContainer>
    </View>
  );
};

export default Loading;
