import { ERROR_PRIVATE_FILES, FETCH_PRIVATE_FILES, UPLOAD_PRIVATE_FILE, DELETE_PRIVATE_FILE, LOADING_PRIVATE_FILES } from './actions';
import { FilePrivateActionType, FilePrivateStateType } from './types';

const initialState: FilePrivateStateType = {
  files: [],
  loading: true,
  error: ''
};

const privateFilesReducer = (state = initialState, action: FilePrivateActionType) => {
  switch (action.type) {
  case LOADING_PRIVATE_FILES:
    return {
      ...state,
      loading: true
    };
  case UPLOAD_PRIVATE_FILE:
    return {
      files: [...state.files, action.payload],
      loading: false,
      error: ''
    };
  case DELETE_PRIVATE_FILE:
    return {
      files: state.files.filter(item => item.file_path !== action.payload),
      loading: false,
      error: ''
    };
  case FETCH_PRIVATE_FILES:
    return {
      files: action.payload,
      loading: false,
      error: ''
    };
  case ERROR_PRIVATE_FILES:
    return {
      files: [],
      loading: false,
      error: action.payload
    };
  default:
    return state;
  };
};

export default privateFilesReducer;
