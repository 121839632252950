import { useState } from 'react';

const useModal = () => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState<string>('');
  const openModal = () => setIsOpenModal(true);
  const closeModal = () => setIsOpenModal(false);
  return [isOpenModal, openModal, closeModal, errorModal, setErrorModal] as const;
};

export default useModal;
