import axios from 'axios';
import { FileType } from '../types/file';
import { getTokenFromStorage } from '../lib/auth';
import { getUriByEnviroment } from '../lib/environment';
const URI = getUriByEnviroment();

export const requestFile = async (userId: number, fileType: string, fileName: string) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `${URI}/api/portal/file/request-file?userId=${userId}&fileType=${fileType}&fileName=${fileName}`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${await getTokenFromStorage()}`
      },
    });
    if (res.data) {
      return res.data;
    } else {
      console.log('ERROR ON FILES.CONTROLLER -> REQUESTFILE');
      throw '😰 Sorry looks like something bad happened on our side!';
    };
  } catch (error: any) {
    console.log('ERROR ON FILES.CONTROLLER -> REQUESTFILE: ', error?.response?.data || error.message);
    throw `😰 ${error?.response?.data || error.message}`;    
  };
};

export const fetchFiles = async (fileType: string) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `${URI}/api/portal/file/list-file/all-by-type?fileType=${fileType}`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${await getTokenFromStorage()}`
      },
    });
    if (res.data) {
      return res.data;
    } else {
      console.log('ERROR ON FILES.CONTROLLER -> FETCHFILES');
      throw '😰 Sorry looks like something bad happened on our side!';
    };
  } catch (error: any) {
    console.log('ERROR ON FILES.CONTROLLER -> FETCHFILES: ', error?.response?.data || error.message);
    throw `😰 ${error?.response?.data || error.message}`;    
  };
};

export const uploadFile = async (file: FileType, platform: string) => {
  const { fileDef, fileBlob } = file;
  try {
    const formData = new FormData();
    if(platform === 'web') {
      formData.append('files', fileBlob, fileDef.name);
    } else {
      formData.append('files', fileDef);
    };
    const res = await axios({
      method: 'POST',
      url: `${URI}/api/portal/file/upload-file?fileType=${fileDef.file_type}&uploadDir=${fileDef.file_type}`,
      data: formData,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `${await getTokenFromStorage()}`
      },
    });
    if (res.data) {
      return res.data;
    } else {
      throw '😰 Sorry looks like something bad happened on our side!';
    };
  } catch (error: any) {
    console.log('ERROR ON UPLOADFILEBYTYPEWEA', error);
    throw `😰 ${error?.response?.data || error.message}`;    
  };
};

export const deleteFile = async (fileType: string, filePath: string) => {
  try {
    const res = await axios({
      method: 'POST',
      url: `${URI}/api/portal/file/delete-file?fileType=${fileType}`,
      data: {
        filePath: filePath,
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${await getTokenFromStorage()}`
      }
    });
    if (res.data.deleted === true) {
      return res.data;
    } else {
      throw '😰 Sorry looks like something bad happened on our side!';
    };
  } catch (error: any) {
    console.log('ERROR ON DELETEFILEBYTYPE', error);
    throw `😰 ${error?.response?.data || error.message}`;    
  };
};
