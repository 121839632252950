import React from 'react';
import { View } from 'react-native';
import tw from 'tailwind-react-native-classnames';
import Tile from '../../../shared/controls/tile-v1';
import Card from '../../../shared/controls/card-v1';
import { TextTitle, TextContent } from '../../../shared/controls/text-v1';

const SectionSmartPayment = () => {

  return (
    <Card styleAdded='web:mr-0'>
      <View style={tw.style('w-full mb-3')}>
        <TextTitle title='Make a smart payment' />
        <TextContent title='Make a direct payment to your landlord through our open banking network.' styleAdded='text-center'/>
      </View>

      <View style={tw.style('w-full flex-row justify-around')}>
        <Tile 
          title='Make a one time payment' 
          primaryColor='#E297FE' 
          secundaryColor='#EEBFFF'
          commingSoon 
        />
        <Tile 
          title='Set up standing order' 
          primaryColor='#E297FE' 
          secundaryColor='#EEBFFF' 
          commingSoon 
        />
      </View>

    </Card>
  );
};

export default SectionSmartPayment;
