import React, { useState } from 'react';
import { View } from 'react-native';
import tw from 'tailwind-react-native-classnames';
import SettingsForm from './form';
import { TextTitle, TextSubTitle } from '../../shared/controls/text-v1';
import { Card, Alert, Button } from '../../shared/controls';
import { useNavigation } from '@react-navigation/core';
import { useDispatch, useSelector } from 'react-redux';
import { actionUpdateTenantDetails } from '../../../redux/tenant-details/actions';
import { RootState } from '../../../redux/store';
import { TenantDetails } from '../../../types/tenantDetails';

const Settings = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { error, loading, tenantDetails } = useSelector((appState: RootState) => appState.tenantDetailsState);
  const [tenantDetailsData, setTenantDetailsData] = useState(tenantDetails);
 
  const handleSubmit = (tenantDetails: TenantDetails) => {
    const tenantDetailsData = {
      uuid: tenantDetails.uuid,
      first_name: tenantDetails.first_name,
      last_name: tenantDetails.last_name,
      email: tenantDetails.email,
      contact_number: tenantDetails.contact_number
    };
    dispatch(actionUpdateTenantDetails(tenantDetailsData));
  };

  const handleInputChange = (key: string, value: string) => {
    setTenantDetailsData({
      ...tenantDetailsData,
      [key]: value
    });
  };

  const handleOnCancel = () => {
    navigation.navigate('Dashboard');
  };

  const Header = () => {
    return (
      <View style={tw.style('bg-purple-500 py-10 px-5 m-4 h-36 rounded-lg')}>
        <TextTitle title='Settings' styleAdded={{color: 'white', textAlign: 'left'}} />
        <TextSubTitle title='Update your contact details, some details can only be changed by your property manager.' styleAdded={{color: 'white'}}/>
      </View>
    );
  };

  return (
    <View style={tw.style('')}>
      <Header />
      <View style={tw.style('h-full')}>
        <Card styleAdded='web:w-1/3 self-center' >
          <SettingsForm tenantDetails={tenantDetailsData} onChangeAction={handleInputChange} />
          {error ? <Alert text={error} type='alert' /> : null}
          <View style={tw.style('flex-row justify-end pt-4 w-full')} >
            <View style={tw.style('mr-2 w-1/4')}>
              <Button title='Cancel' onPress={() => handleOnCancel()} />
            </View>
            <View style={tw.style('w-1/4')}>
              <Button title='Save' onPress={() => handleSubmit(tenantDetailsData)} loading={loading} />
            </View>
          </View>
        </Card>
      </View>
    </View>
  );
};

export default Settings;
