import React from 'react';
import { Text, TouchableOpacity } from 'react-native';
import 'react-native-gesture-handler';
import tw from 'tailwind-react-native-classnames';
import { useNavigation } from '@react-navigation/core';
import { LinkButtonProps } from './types';

const LinkButton = (props: LinkButtonProps) => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity 
      onPress={() => navigation.navigate(props.screen) } 
      style={tw.style('rounded-full p-2 mt-2 w-full items-center bg-pink-200')}
    >
      <Text style={tw.style('text-purple-500 font-bold text-base')}>
        {props.text}
      </Text>
    </TouchableOpacity>
  );
};

export default LinkButton;
