import axios from 'axios';
import { User } from '../types/user';
import { getTokenFromStorage } from '../lib/auth';
import { getUriByEnviroment } from '../lib/environment';
const URI = getUriByEnviroment();

export const getTenantDetails = async () => {
  try {
    const res = await axios({
      method: 'GET',
      url: `${URI}/api/user/me`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${await getTokenFromStorage()}`
      },
    });
    if (res.data) {
      return res.data;
    } else {
      throw '😰 Sorry looks like something bad happened on our side!';
    };
  } catch (error: any) {
    throw `😰 ${error?.response?.data || error.message}`;    
  };
};

export const updateTenantDetails = async (user: User) => {
  try {
    const res = await axios({
      method: 'POST',
      url: `${URI}/api/portal/tenant-details/${user.uuid}`,
      data: user,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${await getTokenFromStorage()}`
      },
    });
    if (res.data) {
      return res.data;
    } else {
      throw '😰 Sorry looks like something bad happened on our side!';
    };
  } catch (error: any) {
    console.log('ERROR ON UPDATETENANTDETAILS: ', error?.response?.data?.error?.message || error.message);
    throw `😰 ${error?.response?.data?.error?.message || error.message}`;    
  };
};

