import React from 'react';
import { Text } from 'react-native';
import tw from 'tailwind-react-native-classnames';
import { TextProps } from './types';

export const TextTitle = (props: TextProps) => {
  return (
    <Text style={tw.style('w-full font-bold text-xl text-center', { color: '#596778' }, props.styleAdded)}>{props.title}</Text>
  );
};

export const TextSubTitle = (props: TextProps) => {
  return (
    <Text style={tw.style('font-bold text-sm mt-1', { color: '#596778' }, props.styleAdded)}>{props.title}</Text>
  );
};

export const TextContent = (props: TextProps) => {
  return (
    <Text style={tw.style('text-xs', { color: '#596778' }, props.styleAdded)}>{props.title}</Text>
  );
};
