import { ERROR_SHARED_FILES, FETCH_SHARED_FILES, UPLOAD_SHARED_FILE, DELETE_SHARED_FILE, LOADING_SHARED_FILES } from './actions';
import { FileSharedActionType, FileSharedStateType } from './types';

const initialState: FileSharedStateType = {
  files: [],
  loading: true,
  error: ''
};

const sharedFilesReducer = (state = initialState, action: FileSharedActionType) => {
  switch (action.type) {
  case LOADING_SHARED_FILES:
    return {
      ...state,
      loading: true
    };
  case UPLOAD_SHARED_FILE:
    return {
      files: [...state.files, action.payload],
      loading: false,
      error: ''
    };
  case DELETE_SHARED_FILE:
    return {
      files: state.files.filter(item => item.file_path !== action.payload),
      loading: false,
      error: ''
    };
  case FETCH_SHARED_FILES:
    return {
      files: action.payload,
      loading: false,
      error: ''
    };
  case ERROR_SHARED_FILES:
    return {
      files: [],
      loading: false,
      error: action.payload
    };
  default:
    return state;
  };
};

export default sharedFilesReducer;
