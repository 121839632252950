import React from 'react';
import { View, Text } from 'react-native';

const Messages = () => {
  return (
    <View>
      <Text>Messages comming soon</Text>
    </View>
  );
};

export default Messages;
