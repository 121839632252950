import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { ScrollView, Platform, View } from 'react-native';
import SectionWelcome from './section-welcome';
import SectionDetails from './section-details';
import SectionSmartPayment from './section-smart-payment';
import SectionTenancyDetails from './section-tenancy-details';
import SectionPaymentDetails from './section-payment-details';
import SectionShared from './section-documents/section-shared';
import SectionPrivate from './section-documents/section-private';
import tw from 'tailwind-react-native-classnames';

const Dashboard = () => {
  const { tenancy } = useSelector((appState: RootState) => appState.tenancyState);
  const { tenantDetails } = useSelector((appState: RootState) => appState.tenantDetailsState);
  const fileTypeSectionShared = `unit/${tenancy.unit_id}/tenancy/${tenancy.tenancy_id}`;

  const DashBoardContent = () => {
    return (
      <View style={tw.style('web:h-full')}>
        <View style={tw.style('web:flex-row')}>
          <View style={tw.style('web:w-7/12')}>
            <SectionWelcome  tenantDetails={tenantDetails} tenancy={tenancy}/>
            <SectionDetails />
            <SectionSmartPayment />
          </View>
          <View style={tw.style('web:w-5/12')}>
            <SectionTenancyDetails tenancy={tenancy} />
            <SectionPaymentDetails tenancy={tenancy} />
          </View>
        </View>
        <SectionShared fileType={fileTypeSectionShared} />
        <SectionPrivate />
      </View>
    );
  };

  if (Platform.OS === 'web') {
    return (
      <DashBoardContent />
    );
  };
  
  return (
    <ScrollView>
      <DashBoardContent />  
    </ScrollView>
  );
};  

export default Dashboard;
