import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import DashboardScreen from '../../screens/dashboard';
import MessagesScreen from '../../screens/messages';
import AgreementsScreen from '../../screens/agreements';
import ReportMaintenanceScreen from '../../screens/report-maintenance';

const DashboardStackNavigation = () => {
  const Stack = createNativeStackNavigator();
  return (
    <Stack.Navigator 
      initialRouteName="Dashboard" 
    >
      <Stack.Screen 
        name= 'Dashboard' 
        component={DashboardScreen} 
        options={{ headerShown: false, title:'Dashboard' }}
      />
      <Stack.Screen 
        name= 'Messages' 
        component={MessagesScreen} 
        options={{ headerShown: false, title:'Messages' }}
      />
      <Stack.Screen 
        name= 'Agreements' 
        component={AgreementsScreen} 
        options={{ headerShown: false, title:'Agreements' }}
      />
      <Stack.Screen 
        name= 'ReportMaitenance' 
        component={ReportMaintenanceScreen} 
        options={{ headerShown: false, title:'Report Maitenance'  }}
      />
    </Stack.Navigator>
  );
};

export default DashboardStackNavigation;
