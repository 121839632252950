import React from 'react';
import { PdfSvg, FileSvg, ImageSvg, ExcelSvg, DocSvg } from '../components/shared/svg/icons';

const iconByFileExtension = {
  pdf: <PdfSvg />,
  svg: <ImageSvg />,
  jpg: <ImageSvg />,
  png: <ImageSvg />,
  xls: <ExcelSvg />,
  xlsx: <ExcelSvg />,
  xlsm: <ExcelSvg />,
  doc: <DocSvg />,
};

const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

export const getFileNameByPath = (filePath: any) => {
  const fileName = filePath.split('/').pop();
  return decodeURI(fileName).replace(/%26/g, '&');
};

export const getFileExtensionByName = (fileName: any) => {
  return fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
};

export const getIconByFileExtension = (fileExtension: string) => {
  const defaultIcon = <FileSvg />;
  return iconByFileExtension[fileExtension.toLowerCase()] || defaultIcon;
};

export const getBytesFromSize = (bytes: number) => {
  if (isNaN(bytes)) return 'n/a';
  if (bytes === 0) return 'n/a';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};


export const getBlobFromDataUri = (dataURI: string) => {
  const splitDataURI = dataURI.split(',');
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++)
    ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};
