import { FETCH_TENANT_DETAILS, UPDATE_TENANT_DETAILS, ERROR_TENANT_DETAILS, LOADING_TENANT_DETAILS } from './actions';
import { TenantDetailsActionType, TenantDetailsStateType } from './types';

const initialState: TenantDetailsStateType = {
  tenantDetails: {
    uuid: '',
    first_name: '',
    last_name: '',
    email: '',
    contact_number: '',
  },
  loading: false,
  error: ''
};

const tenantDetailsReducer = (state = initialState, action: TenantDetailsActionType) => {
  switch (action.type) {
  case FETCH_TENANT_DETAILS:
    return {
      ...initialState, 
      loading: false,
      tenantDetails: action.payload
    };
  case LOADING_TENANT_DETAILS:
    return {
      ...state,
      loading: true
    };
  case ERROR_TENANT_DETAILS:
    return {
      ...state,
      loading: false,
      error: action.payload
    };
  case UPDATE_TENANT_DETAILS:
    return {
      ...state,
      error: '',
      loading: false,
      tenantDetails: action.payload,
    };
  default:
    return state;
  };
};

export default tenantDetailsReducer;
