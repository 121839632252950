export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const ERROR_USER = 'ERROR_USER';
export const SET_USER = 'SET_USER';
export const LOADING_USER = 'LOADING_USER';

import { AppDispatch } from '../store';
import * as storage from '../../lib/asyncStorage';
import * as userCtrl from '../../controllers/user.controller';
import { Credential } from '../../types/credential';

export const actionCheckAuth = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const token = await storage.getValue('octo-token-key');
      if (token) {
        const user = await userCtrl.getUserData();
        dispatch({
          type: SET_USER,
          payload: { token, user }
        });
      } else {
        storage.removeValue('octo-token-key');
        dispatch({
          type: LOGOUT_USER,
          payload: ''
        });
      };
    } catch (error) {
      storage.removeValue('octo-token-key');
      dispatch({
        type: LOGOUT_USER,
        payload: ''
      });
    };
  };
};

export const actionGetLogin = (credentials: Credential) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: LOADING_USER });
      const data = await userCtrl.getToken(credentials);
      await storage.storeValue('octo-token-key', data.token);
      dispatch({
        type: LOGIN_USER,
        payload: data
      });
    } catch (error: any) {
      dispatch({
        type: ERROR_USER,
        payload: error
      });
    };
  };
};

export const actionSetLogout = () => {
  return async (dispatch: AppDispatch) => {
    await storage.removeValue('octo-token-key');
    dispatch({
      type: LOGOUT_USER
    });
  };
};
