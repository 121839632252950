import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { View, TouchableOpacity, Linking } from 'react-native';
import tw from 'tailwind-react-native-classnames';
import { TextContent } from '../../../../shared/controls/text-v1';
import { ActivityIndicator } from '../../../../shared/controls';
import { getFileExtensionByName, getFileNameByPath, getIconByFileExtension, getBytesFromSize } from '../../../../../lib/files';
import { CloseSvg } from '../../../../shared/svg/icons';
import { SingleFileProps } from './types';
import { requestFile } from '../../../../../controllers/files.controller';

const SingleDocument = (props: SingleFileProps) => {
  const { file, actionDelete } = props;
  const fileName = getFileNameByPath(file.file_path);
  const fileExtension = getFileExtensionByName(fileName);
  const metadata =  JSON.parse(file.metadata);
  const fileSize = getBytesFromSize(metadata.size);
  const { user } = useSelector((appState: RootState) => appState.userState);
  const showDeleteIcon = user.id === file.tenant_details_id;
  const [loading, setLoading] = useState(false);

  const handleOpenUrl = async (file: any) => {
    setLoading(true);
    const userId = file.user_id || file.tenant_details_id;
    const fileType = file.file_type;
    const fileName = getFileNameByPath(file.file_path);
    const url = await requestFile(userId, fileType, fileName);
    Linking.openURL(url);
    setLoading(false);
  };

  const DeleteIcon = (props: any) => {
    const { filePath } = props;
    return (
      <TouchableOpacity 
        style={tw.style('absolute top-0 right-0')}
        onPress={() => actionDelete(filePath)}
      >
        <CloseSvg width={21} height={21} color='#D0342C' />
      </TouchableOpacity>
    );
  };

  return (
    <View style={tw.style('items-center justify-center h-28 w-24')} >
      {loading 
        ? <ActivityIndicator />
        : (
          <>
            <TouchableOpacity 
              style={tw.style('items-center justify-center h-full w-full')}
              onPress={() => handleOpenUrl(file)}
            >
              {getIconByFileExtension(fileExtension)}
              <TextContent title={`${fileName.slice(0, 10)}...`} styleAdded='mt-1' />
              <TextContent title={`${fileSize} / ${fileExtension}`} styleAdded='mt-1' />
            </TouchableOpacity>
            {showDeleteIcon && <DeleteIcon filePath={file.file_path} />}
          </>
        )
      }
    </View>
  );
};

export default SingleDocument;
