import React from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { ButtonProps } from './types';
import tw from 'tailwind-react-native-classnames';
import ActivityIndicator from '../activity-indicator-v1';

const Button = (props: ButtonProps) => {
  const { onPress, title, loading = false } = props;
  return (
    <TouchableOpacity
      style={tw.style('bg-purple-500 rounded-md p-2 h-10 w-full items-center justify-center font-medium shadow')} 
      onPress={() => onPress()}
    >
      {loading 
        ? <ActivityIndicator color='white' size='small' />
        : <Text style={tw.style('text-white')} >{title}</Text>
      }
    </TouchableOpacity>
  );
};

export default Button;
