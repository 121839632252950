import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { View, FlatList, TouchableOpacity, Platform } from 'react-native';
import tw from 'tailwind-react-native-classnames';
import { TextTitle } from '../../../../shared/controls/text-v1';
import { Card, Modal, ActivityIndicator } from '../../../../shared/controls';
import { PlusSvg } from '../../../../shared/svg/icons';
import { actionDeleteSharedFile, actionUploadSharedFile } from '../../../../../redux/files/shared/actions';
import useFile from '../../../../../hooks/useFile';
import useModal from '../../../../../hooks/useModal';
import SingleDocument from '../single-document';
import { SectionSharedProps } from './types';

const SectionShared = (props: SectionSharedProps) => {
  const { fileType } = props;
  const dispatch = useDispatch();
  const { error, files, loading } = useSelector((appState: RootState) => appState.sharedFilesState);
  const { file, setFile, pickFile } = useFile(fileType);
  const [isOpenModal, openModal, closeModal] = useModal();
  const [currentFilePath, setCurrentFilePath] = useState<string>('');
  
  useEffect(() => {
    if(file) {
      dispatch(actionUploadSharedFile(file, Platform.OS));
      setFile(undefined);
    };
  }, [file]);
  
  const handleDeleteFile = async (filePath: string) => {
    try {
      dispatch(actionDeleteSharedFile(fileType, filePath));
      closeModal();
    } catch (error) {
      console.log('ERROR ON HANDLEDELETEFILE: ', error);
    };
  };

  const Header = (props: any) => {
    return (
      <View style={tw.style('flex-row w-full justify-between')} >
        <View>
          <TextTitle title={props.title} />
        </View>
        {loading 
          ? <ActivityIndicator /> 
          : 
          <TouchableOpacity onPress={pickFile}>
            <PlusSvg />
          </TouchableOpacity>
        }
      </View>
    );
  };

  const renderFile = ({ item }: any) => {
    return (
      <SingleDocument 
        file={item} 
        actionDelete={() => {
          setCurrentFilePath(item.file_path);
          openModal();
        }} 
      />
    );
  };

  const Body = (props: any) => {
    const { files } = props;
    if (files.length === 0) return null;
    return (
      <FlatList 
        style={tw.style('w-full mt-1')}
        horizontal
        data={files}
        renderItem={renderFile}
        keyExtractor={file => file.id}
      />
    );
  };

  return (
    <Card>
      <Header title='Shared Documents' />
      <Body files={files}/>
      {/* ------------ MODAL INFO ------------ */}
      <Modal
        isOpen={isOpenModal}
        actionClose={closeModal}
        title='Delete'
        subTitle='Are you sure you want to delete this file?'
        textActionOne='Yes'
        actionOne={() => handleDeleteFile(currentFilePath)}
        textActionTwo='Cancel'
        actionTwo={closeModal}
      />
    </Card>
  );
};

export default SectionShared;
