import React from 'react';
import 'react-native-gesture-handler';
import { View } from 'react-native';
import tw from 'tailwind-react-native-classnames';
import LinkButton from './link-button';
import Footer from './footer';
import LogoSvg from '../../shared/svg/logo';

const CustomDrawer = () => {
  return (
    <View style={tw.style('items-center m-2 h-full')}>
      <View style={tw.style('mt-10 mb-5 items-center')} >
        <LogoSvg showText width={120} height={120} />
      </View>
      <View style={tw.style('w-full')} >
        <LinkButton text='Dashboard' screen='Dashboard' />
      </View>
      <Footer />
    </View> 
  );
};

export default CustomDrawer;
