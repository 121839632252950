import React from "react";
import { View } from "react-native";
import tw from 'tailwind-react-native-classnames';
import { TextContent } from '../text-v1/index';
import { AlertProps } from "./types";

const Alert = (props: AlertProps) => {

  const getColorByType = (type: string) => {
    if (type === 'alert') return 'bg-red-200';
    if (type === 'info') return 'bg-green-200';
    if (type === 'warning') return 'bg-yellow-200';
    return 'bg-red-200';
  };

  return (
    <View style={tw.style('p-3 rounded-md w-full my-3', getColorByType(props.type))}>
      <TextContent title={props.text} />
    </View>
  );
};

export default Alert;