import { useNavigation } from "@react-navigation/core";
import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import tw from 'tailwind-react-native-classnames';
import Gradient from '../gradient';
import { TileProps } from './types';

const Tile = (props: TileProps) => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      onPress={() => {
        if (props.screen) navigation.navigate(props.screen);
      }}
    >
      <Gradient primaryColor={props.primaryColor} secundaryColor={props.secundaryColor}>
        <View style={tw.style('justify-center h-36 w-36 p-2')}>
          {props.icon &&
            <View style={tw.style('items-center justify-center')}>
              {props.icon}
            </View>
          }
          <Text style={tw.style('text-white text-base font-extrabold text-center')}>{props.title}</Text> 
          {props.commingSoon &&
            <View style={tw.style('border border-white rounded-2xl p-1 m-1 w-9/12 self-center')}>
              <Text style={tw.style('text-white text-xs text-center')}>comming soon</Text>
            </View>
          }
        </View>
      </Gradient>
    </TouchableOpacity>
  );
};

export default Tile;