import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { SvgProps } from '../../types';

const AvatarSvg = ({color='#475F7B', width=57, height=57}: SvgProps) => {
  return (
    <Svg 
      width={width}
      height={height}
      viewBox="0 0 57 57" 
      fill="none"
    >
      <Path 
        d="M28.5 57C44.2157 57 57 44.2157 57 28.5C57 12.7843 44.2157 0 28.5 0C12.7843 0 0 12.7843 0 28.5C0 44.2157 12.7843 57 28.5 57ZM28.5 3.55959C42.2498 3.55959 53.4346 14.7444 53.4346 28.5C53.4346 42.2498 42.2498 53.4404 28.5 53.4404C14.7502 53.4404 3.56541 42.2498 3.56541 28.5C3.56541 14.7502 14.7502 3.55959 28.5 3.55959ZM28.5 9.71908C34.642 9.71908 39.6266 14.6571 39.6266 20.7527C39.6266 26.8482 34.6479 31.7862 28.5 31.7862C22.358 31.7862 17.3792 26.8482 17.3792 20.7527C17.3792 14.6571 22.358 9.71908 28.5 9.71908ZM28.5 28.6338C32.8913 28.6338 36.4451 25.1033 36.4451 20.7527C36.4451 16.3962 32.8855 12.8715 28.5 12.8715C24.1087 12.8715 20.5549 16.402 20.5549 20.7527C20.5549 25.1033 24.1087 28.6338 28.5 28.6338ZM28.5 28.6338C37.2768 28.6338 44.3902 35.689 44.3902 44.396H41.2087C41.2087 37.4339 35.5145 31.7862 28.4942 31.7862C21.4739 31.7862 15.7797 37.4339 15.7797 44.396H12.604C12.6098 35.689 19.7232 28.6338 28.5 28.6338Z" 
        fill={color} 
      />
    </Svg>
  );
};

export default AvatarSvg;
