import React from 'react';
import { View, Text, Platform } from 'react-native';
import tw from 'tailwind-react-native-classnames';
import Gradient from '../../../shared/controls/gradient';
import DonutChar from '../../../shared/svg/donut-char';
import DonutCharAnimated from '../../../shared/animations/donut-char';
import moment from 'moment';
import { SectionWelcomeProps } from './types';

const getDatesToPlot = (startDate, endDate) => {
  const totalDays = moment(endDate).diff(moment(startDate), 'days');
  const remainsDays = moment(endDate).diff(moment(), 'days');
  return { totalDays, remainsDays };
};

const SectionWelcome = (props: SectionWelcomeProps) => {
  const { tenantDetails, tenancy } = props;
  const { totalDays, remainsDays } = getDatesToPlot(tenancy.start_date, tenancy.end_date);
  return (
    <View style={tw.style('m-4 web:mr-0')}>
      <Gradient>
        <View style={tw.style('flex-row py-12 px-5 h-36 items-center justify-between')}>
          <View style={tw.style('')}>
            <Text style={tw.style('text-white text-lg')}>Welcome back</Text>
            <Text style={tw.style('text-white text-3xl')}>{tenantDetails?.first_name}</Text>
          </View>
          <View style={tw.style('')}>
            {Platform.OS === 'web' 
              ? <DonutChar value={remainsDays} maxValue={totalDays} />
              : <DonutCharAnimated value={remainsDays} maxValue={totalDays} />
            }
          </View>
        </View>
      </Gradient>
    </View>
  );
};

export default SectionWelcome;
