import React, { useEffect, useRef } from 'react';
import { View, Animated, StyleSheet, Text } from 'react-native';
import Svg, { G, Circle } from 'react-native-svg';
import { DonutCharAnimatedProps } from './types';
import tw from 'tailwind-react-native-classnames';

const AnimatedCircle = Animated.createAnimatedComponent(Circle);

const DonutCharAnimation = ({
  value = 150,
  textValue = 'Days Remaining',
  radius = 60,
  strokeWidth = 20,
  color = 'white',
  fontColor = 'white',
  duration = 400,
  delay = 500,
  maxValue = 300
}: DonutCharAnimatedProps) => {
  if(isNaN(maxValue) || isNaN(value) || value > maxValue) return null;
  const circleRef = useRef();
  const animatedValue = useRef(new Animated.Value(0)).current;
  const halfCircle = radius + strokeWidth;
  const circleCircunference = 2 * Math.PI * radius;

  const animation = (toValue: number) => {
    return Animated.timing(animatedValue, {
      toValue,
      duration,
      delay: delay,
      useNativeDriver: true
    }).start();
  };

  useEffect(() => {
    animation(value);
    animatedValue.addListener((v) => {
      if(circleRef?.current) {
        const newMaxValue = (100 * v.value) / maxValue;
        const strokeDashoffset = circleCircunference - (circleCircunference * newMaxValue) / 100;
        circleRef.current.setNativeProps({
          strokeDashoffset,
        });
      };
    }), [];
    return () => {
      animatedValue.removeAllListeners();
    };
  }, [value, maxValue]);

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      height: radius * 2,
      width: radius * 2,
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    container2: {
      marginHorizontal: 25
    },
    container3: {
      textAlign: 'center',
      fontSize: 16,
      color: fontColor,
    },
    container4: {
      textAlign: 'center',
      fontSize: 10,
      color: fontColor,
    }
  });

  return (
    <View>
      <Svg 
        width={radius * 2} 
        height={radius * 2} 
        viewBox={`0 0 ${halfCircle * 2} ${halfCircle * 2}`}
      >
        <G rotation='-90' origin={`${halfCircle}, ${halfCircle}`}>
          <Circle 
            cx='50%'
            cy='50%'
            stroke={color}
            strokeWidth={strokeWidth}
            r={radius}
            fill='transparent'
            strokeOpacity={0.2} 
          />
          <AnimatedCircle 
            ref={circleRef}
            cx='50%'
            cy='50%'
            stroke={color}
            strokeWidth={strokeWidth}
            r={radius}
            fill='transparent'
            strokeDasharray={circleCircunference}
            strokeDashoffset={circleCircunference}
            // strokeLinecap='round'
          />
        </G>
      </Svg>
      <View style={styles.container}>
        <View style={styles.container2}>
          <Text style={tw.style('font-bold', styles.container3)}>{`${value}`}</Text>
        </View>
        <View style={styles.container2}>
          <Text style={styles.container4}>{textValue}</Text>
        </View>
      </View>
    </View>
  );
};

export default DonutCharAnimation;
