import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import userReducer from './user/userReducer';
import tenantDetailsReducer from './tenant-details/tenantDetailsReducer';
import tenancyReducer from './tenancy/tenancyReducer';
import privateFilesReducer from './files/private/privateFilesReducer';
import sharedFilesReducer from './files/shared/sharedFilesReducer';

const rootReducer = combineReducers({
  userState: userReducer,
  tenantDetailsState: tenantDetailsReducer,
  tenancyState: tenancyReducer,
  privateFilesState: privateFilesReducer,
  sharedFilesState: sharedFilesReducer
});

export const store = createStore(
  rootReducer, 
  applyMiddleware(thunk)
);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
