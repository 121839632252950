import React from 'react';
import { View } from 'react-native';
import tw from 'tailwind-react-native-classnames';
import { CardProps } from './types';

const Card = ({styleAdded, children}: CardProps) => {
  return (
    <View style={tw.style('mx-4 my-2')}>
      <View style={tw.style('w-full bg-white items-center justify-center rounded-lg p-5 shadow web:shadow-md', styleAdded)}>
        {children}
      </View>
    </View>
    
  );
};

export default Card;
