import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import tw from 'tailwind-react-native-classnames';
import { TextContent, TextTitle } from '../../shared/controls/text-v1';
import { Card, Button, Alert } from '../../shared/controls';
import LogoSvg from '../../shared/svg/logo';
import { actionGetLogin, actionCheckAuth } from '../../../redux/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Credential } from '../../../types/credential';
import { RootState } from '../../../redux/store';
import LoadingScreen from '../../../screens/loading';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import LoginForm from './form';

const Login = () => {
  const dispatch = useDispatch();
  const { error, loading, isAuth } = useSelector((appState: RootState) => appState.userState);

  useEffect(() => {
    const init = async () => {
      await dispatch(actionCheckAuth());
    };
    init();
  }, []);

  // ------------ STATES ------------
  const [loginData, setLoginData] = useState({
    password: '',
    email: '',
    mode: 'portal'
  });

  // ------------ HANDLES ------------
  const handleSubmit = (credentials: Credential) => {
    dispatch(actionGetLogin(credentials));
  };

  const handleInputChange = (key: any, value: any) => {
    setLoginData({
      ...loginData,
      [key]: value
    });
  };

  const LoginHeader = () => {
    return (
      <View style={tw.style('items-center mb-2')} >
        <LogoSvg showText width={100} height={100} />
        <View style={tw.style('items-center mt-2')} >
          <TextTitle title='Login' />
          <TextContent title='Login to your tenants portal' />
        </View>
      </View>
    );
  };

  // ------------ JSX ------------
  if(isAuth) return <LoadingScreen/>;
  return (
    <SafeAreaProvider style={tw.style('justify-center', { backgroundColor: '#FBFAFF' })} >
      <Card styleAdded='self-center web:w-1/3'>
        <LoginHeader />
        <LoginForm loginData={loginData} onInputChange={handleInputChange} />
        <View style={tw.style('w-full items-end mb-5 mr-5')} >
          <TextContent title='Forgot your password?' />
        </View>
        {error ? <Alert text={error} type='alert' /> : null}
        <View style={tw.style('w-full')} >
          <Button title='Sign in' loading={loading} onPress={() => handleSubmit(loginData)} />
        </View>
      </Card>
    </SafeAreaProvider>
  );
};

export default Login;
