import React, { useRef, useEffect } from 'react';
import { Animated } from 'react-native';
import { HearbeatAnimationProps } from './types';

const HeartbeatAnimation = (props: HearbeatAnimationProps) => {
  const { children, durationIn = 1200, durationOut = 1500 } = props;
  const fadeAnim = useRef(new Animated.Value(0)).current;
  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: durationIn,
          useNativeDriver: true,
        }),
        Animated.timing(fadeAnim, {
          toValue: 0,
          duration: durationOut,
          useNativeDriver: true,
        }),
      ])
    ).start();
  }, [fadeAnim]);
  return ( 
    <Animated.View style={{ opacity: fadeAnim }}>
      {children}
    </Animated.View>
  );
};

export default HeartbeatAnimation;
