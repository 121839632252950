import React from 'react';
import { View } from 'react-native';
import tw from 'tailwind-react-native-classnames';
import { LinearGradient } from 'expo-linear-gradient';
import { PropsInterface } from './types';

const Gradient = ({ children, primaryColor='#8A69FE', secundaryColor='#E297FE' }: PropsInterface) => {
  return (
    <View>
      <LinearGradient 
        style={tw.style('p-1', { borderRadius: 10 })}
        colors={[primaryColor, secundaryColor]} 
        start={{ x: 0, y: 0 }}
        end={{ x: 0.5, y: 1 }}
      >
        {children}
      </LinearGradient>
    </View>
  );
};

export default Gradient;
