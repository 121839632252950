import { FETCH_TENANCY, LOADING_TENANCY, ERROR_TENANCY} from './actions';
import { TenancyStateType, TenancyActionType } from './types';

const initialState: TenancyStateType = {
  tenancy: {
    landlord_id: '',
    address_2: '',
    address_1: '',
    city: '',
    postcode: '',
    country: '',
    unit_id: '',
    tenancy_id: '',
    start_date: '',
    end_date: '',
    letting_contact_fullname: '',
    emergency_contact_fullname: '',
    emergency_contact_email: '',
    emergency_contact_number: '',
    account_holder_name: '',
    account_bank: '',
    account_sort_code: '',
    account_number: ''
  },
  loading: false,
  error: ''
};

const tenancyReducer = (state = initialState, action: TenancyActionType) => {
  switch (action.type) {
  case FETCH_TENANCY:
    return {
      tenancy: action.payload,
      loading: false,
      error: ''
    };
  case LOADING_TENANCY:
    return {
      ...state,
      loading: true
    };
  case ERROR_TENANCY:
    return {
      tenancy: initialState.tenancy,
      loading: false,
      error: action.payload
    };
  default:
    return state;
  };
};

export default tenancyReducer;
