import React from 'react';
import { AgreementSvg, ReportSvg, MessageSvg} from '../../../shared/svg/icons';

export const tileContent = [
  { 
    title: 'Messages', 
    icon: <MessageSvg />, 
    primaryColor: '#E297FE', 
    secundaryColor: '#EEBFFF', 
    // screen: 'Messages',
    commingSoon: true
  },
  { 
    title: 'Agreements', 
    icon: <AgreementSvg />, 
    primaryColor: '#35C8FC', 
    secundaryColor: '#9AE5FF', 
    // screen: 'Agreements',
    commingSoon: true
  },
  { 
    title: 'Report Maitenance', 
    icon: <ReportSvg />, 
    primaryColor: '#8869FE', 
    secundaryColor: '#C6B7FF', 
    // screen: 'ReportMaitenance', 
    commingSoon: true
  },
  // { 
  //   title: 'Other', 
  //   icon: <ReportSvg />, 
  //   primaryColor: '#8869FE', 
  //   secundaryColor: '#C6B7FF', 
  //   commingSoon: true
  // },
];
