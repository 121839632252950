import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { LogoSvgProps } from './types';

const LogoText = () => {
  return (
    <>
      <Path 
        d="M36.545 142.912C41.2646 142.912 43.6066 139.75 43.6066 134.808C43.6066 129.901 41.2646 126.74 36.545 126.74C31.8254 126.74 29.4478 129.901 29.4478 134.808C29.4478 139.75 31.8254 142.912 36.545 142.912M36.5449 121.652C44.4581 121.652 49.284 127.357 49.284 134.808C49.284 142.294 44.4581 148 36.5449 148C28.5961 148 23.7701 142.294 23.7701 134.808C23.7701 127.357 28.5961 121.652 36.5449 121.652" 
        fill="#596778"/>
      <Path 
        d="M64.101 121.617C70.9629 121.617 75.4912 125.547 76.2225 131.879H70.6495C70.0922 128.495 67.5144 126.711 64.1706 126.711C59.8863 126.711 57.0651 129.513 57.0651 134.79C57.0651 140.103 59.8863 142.905 64.1706 142.905C67.5144 142.905 70.0922 141.086 70.6495 137.702H76.2225C75.4912 144.07 70.9629 148 64.101 148C56.2985 148 51.4918 142.287 51.4918 134.79C51.4918 127.33 56.2985 121.617 64.101 121.617"
        fill="#596778"
      />
      <Path 
        d="M99 127.163H90.8518V148H85.1835V127.163H77V122H99V127.163Z" 
        fill="#596778"
      />
      <Path 
        d="M112.715 142.912C117.434 142.912 119.776 139.75 119.776 134.808C119.776 129.901 117.434 126.74 112.715 126.74C107.995 126.74 105.618 129.901 105.618 134.808C105.618 139.75 107.995 142.912 112.715 142.912M112.715 121.652C120.628 121.652 125.454 127.357 125.454 134.808C125.454 142.294 120.628 148 112.715 148C104.766 148 99.9399 142.294 99.9399 134.808C99.9399 127.357 104.766 121.652 112.715 121.652" 
        fill="#596778"
      />
    </>
  );
};

const LogoBody = () => {
  return (
    <>
      <Path 
        d="M112.027 27.3953C109.613 26.9529 107.131 26.7219 104.593 26.7219C99.4745 26.7219 94.5712 27.661 90.0375 29.3852C86.1337 24.1508 79.9592 20.7755 73.0154 20.7755C66.0679 20.7755 59.8972 24.1508 55.9896 29.3852C51.4559 27.661 46.5526 26.7219 41.4343 26.7219C38.896 26.7219 36.4101 26.9529 34 27.3953C40.001 11.3732 55.2052 0 73.0154 0C90.8219 0 106.026 11.3732 112.027 27.3953"
        fill="#35C8FC"
      />
      <Path 
        d="M58.7487 83.3666C54.8455 88.6168 48.6775 92.0087 41.7343 92.0087C29.9217 92.0087 20.3462 82.1991 20.3462 70.1033C20.3462 61.5283 25.1528 54.108 32.1564 50.5151C35.0353 49.0305 38.2917 48.198 41.7343 48.198C46.1282 48.198 50.2151 49.5563 53.6149 51.8888C57.045 54.2394 59.7704 57.5798 61.4112 61.5103C62.5109 64.147 63.1174 67.0517 63.1174 70.1033C63.1174 75.0855 61.4892 79.6835 58.7487 83.3666M82.8697 62.8866C81.7725 56.2575 79.183 50.1465 75.4711 44.935C74.7816 43.9711 74.0568 43.0355 73.2943 42.1359L73.2918 42.1334C68.7469 36.7543 62.911 32.5557 56.2799 30.0299C51.7502 28.3005 46.8505 27.3597 41.7343 27.3597C39.1977 27.3597 36.7138 27.5891 34.3055 28.035C14.7973 31.6253 0 49.0923 0 70.1033C0 93.7072 18.6853 112.842 41.7343 112.842C54.3397 112.842 65.639 107.117 73.2918 98.0681L73.2943 98.0655C74.0845 97.1351 74.8294 96.1686 75.539 95.1737C76.9508 93.1736 78.2041 91.0421 79.2711 88.8024C79.5328 88.2431 79.7895 87.676 80.0311 87.1013C80.2727 86.5291 80.5017 85.9492 80.7206 85.3641C82.4973 80.6268 83.4661 75.4798 83.4661 70.1033C83.4661 67.6445 83.2623 65.2346 82.8697 62.8866" 
        fill="#E297FE"
      />
      <Path 
        d="M105.625 92.0087C98.6788 92.0087 92.5133 88.6168 88.6051 83.3718C85.8973 79.7351 84.2769 75.2066 84.2366 70.2966V70.1033C84.2366 70.0518 84.2366 70.0054 84.2392 69.9564C84.2543 66.9641 84.8605 64.1109 85.9401 61.518C87.5786 57.5823 90.3091 54.2394 93.7414 51.8888C97.1387 49.5563 101.226 48.198 105.625 48.198C109.07 48.198 112.321 49.0305 115.203 50.5151C122.201 54.1131 127.008 61.5335 127.008 70.1033C127.008 82.1991 117.432 92.0087 105.625 92.0087M113.054 28.035C110.64 27.5891 108.161 27.3597 105.625 27.3597C100.509 27.3597 95.6087 28.3005 91.0764 30.0299C84.4431 32.5557 78.6072 36.7543 74.0623 42.1334C72.0793 44.4762 70.3429 47.0485 68.8908 49.796C67.771 51.9069 66.8248 54.1312 66.0673 56.4405C65.8408 57.1338 65.6294 57.8349 65.4331 58.5437C65.2393 59.255 65.0657 59.969 64.9071 60.6932C64.7486 61.4175 64.6049 62.1469 64.4844 62.884C64.0915 65.2295 63.8877 67.6445 63.8877 70.1033C63.8877 75.4798 64.8566 80.6268 66.6285 85.3641C68.3775 90.0498 70.9116 94.3438 74.0623 98.0681C81.7149 107.117 93.0144 112.842 105.625 112.842C128.669 112.842 147.354 93.7072 147.354 70.1033C147.354 49.0923 132.557 31.6253 113.054 28.035" 
        fill="#8F80FF"
      />
    </>
  );
};

const LogoSvg = ({ width=148, height=148, showText=false }: LogoSvgProps) => {
  return (
    <Svg 
      width={width}
      height={height}
      viewBox={`0 0 148 ${showText ? '148' : '112'}`}
      fill="none" 
      // style={{ borderWidth: 1, borderColor: 'black' }}
    >
      <LogoBody />
      {showText && <LogoText />}
    </Svg>
  );
};

export default LogoSvg;
