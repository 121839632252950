import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import tw from 'tailwind-react-native-classnames';

const CustomActivityIndicator = (props: any) => {
  const { color='#7B61FF', size='large' } = props;
  return (
    <View style={tw.style('items-center justify-center')}>
      <ActivityIndicator color={color} size={size} />
    </View>
  );
};

export default CustomActivityIndicator;
