export const FETCH_PRIVATE_FILES = 'FETCH_PRIVATE_FILES';
export const UPLOAD_PRIVATE_FILE = 'UPLOAD_PRIVATE_FILE';
export const DELETE_PRIVATE_FILE = 'DELETE_PRIVATE_FILE';
export const ERROR_PRIVATE_FILES = 'ERROR_PRIVATE_FILES';
export const LOADING_PRIVATE_FILES = 'LOADING_PRIVATE_FILES';

import { AppDispatch } from '../../store';
import * as filesCtrl from '../../../controllers/files.controller';
import { FileType } from '../../../types/file';

export const actionFetchPrivateFiles = (fileType: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: LOADING_PRIVATE_FILES });
      const data = await filesCtrl.fetchFiles(fileType);
      dispatch({
        type: FETCH_PRIVATE_FILES,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: ERROR_PRIVATE_FILES,
        payload: error
      });
    };
  };
};

export const actionUploadPrivateFile = (file: FileType, platform: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: LOADING_PRIVATE_FILES });
      const data = await filesCtrl.uploadFile(file, platform);
      dispatch({
        type: UPLOAD_PRIVATE_FILE,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: ERROR_PRIVATE_FILES,
        payload: error
      });
    };
  };
};

export const actionDeletePrivateFile = (fileType: string, filePath: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: LOADING_PRIVATE_FILES });
      await filesCtrl.deleteFile(fileType, filePath);
      dispatch({
        type: DELETE_PRIVATE_FILE,
        payload: filePath
      });
    } catch (error) {
      dispatch({
        type: ERROR_PRIVATE_FILES,
        payload: error
      });
    };
  };
};
