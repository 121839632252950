import { LOGIN_USER, LOGOUT_USER, ERROR_USER, SET_USER, LOADING_USER } from './actions';
import { UserStateType, UserActionType } from './types';

const initialState: UserStateType = {
  user: {
    uuid: '',
    first_name: '',
    last_name: '',
    email: '',
    contact_number: '',
  },
  isAuth: false,
  token: '',
  loading: false,
  error: ''
};

const userReducer = (state = initialState, action: UserActionType) => {
  switch (action.type) {
  case LOGIN_USER:
    return {
      ...initialState, 
      isAuth: true, 
      token: action.payload.token,
      user: action.payload.user,
      loading: false
    };
  case LOADING_USER:
    return {
      ...state,
      loading: true
    };
  case LOGOUT_USER:
    return initialState;
  case ERROR_USER:
    return {
      ...initialState,
      error: action.payload
    };
  case SET_USER:
    return {
      ...initialState, 
      isAuth: true, 
      user: action.payload.user,
      token: action.payload.token
    };
  default:
    return state;
  };
};

export default userReducer;
