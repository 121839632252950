export const FETCH_TENANCY = 'FETCH_TENANCY';
export const ERROR_TENANCY = 'ERROR_TENANCY';
export const LOADING_TENANCY = 'LOADING_TENANCY';

import { AppDispatch } from '../store';
import * as tenancyCtrl from '../../controllers/tenancy.controller';

export const actionFetchTenancy = (tenantDetailsUuid: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: LOADING_TENANCY });
      const data = await tenancyCtrl.fetchTenancy(tenantDetailsUuid);
      dispatch({
        type: FETCH_TENANCY,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: ERROR_TENANCY,
        payload: error
      });
    };
  };
};
