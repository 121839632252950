import React from 'react';
import Dashboard from '../../components/screens/dashboard';

const DashboardScreen = () => {
  return (
    <Dashboard />
  );
};

export default DashboardScreen;
