import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { SvgProps } from '../../types';

const MessageSvg = ({color='white', width=34, height=34}: SvgProps) => {
  return (
    <Svg 
      width={width} 
      height={height}
      viewBox="0 0 34 34" 
    >
      <Path 
        d="M11.6875 14.344H22.3125C22.8995 14.344 23.375 13.8685 23.375 13.2815V12.219C23.375 11.6319 22.8995 11.1565 22.3125 11.1565H11.6875C11.1005 11.1565 10.625 11.6319 10.625 12.219V13.2815C10.625 13.8685 11.1005 14.344 11.6875 14.344ZM10.625 19.6565C10.625 20.2435 11.1005 20.719 11.6875 20.719H22.3125C22.8995 20.719 23.375 20.2435 23.375 19.6565V18.594C23.375 18.0069 22.8995 17.5315 22.3125 17.5315H11.6875C11.1005 17.5315 10.625 18.0069 10.625 18.594V19.6565ZM17 27.7002C15.9096 27.7002 14.8192 27.3642 13.8882 26.6915L0 16.6589V30.8127C0 32.5731 1.42707 34.0002 3.1875 34.0002H30.8125C32.5729 34.0002 34 32.5731 34 30.8127V16.6589L20.1118 26.6915C19.1808 27.3636 18.0904 27.7002 17 27.7002ZM32.7788 10.8211C32.1911 10.3602 31.6339 9.92661 30.8125 9.30638V6.37521C30.8125 4.61478 29.3854 3.18771 27.625 3.18771H22.4752C22.2733 3.04161 22.0854 2.90481 21.8749 2.75208C20.7586 1.93728 18.5406 -0.0230373 17 0.000204888C15.4594 -0.0230373 13.2421 1.93728 12.1251 2.75208C11.9146 2.90481 11.7267 3.04161 11.5248 3.18771H6.375C4.61457 3.18771 3.1875 4.61478 3.1875 6.37521V9.30638C2.36605 9.92595 1.80891 10.3602 1.22121 10.8211C0.840808 11.1192 0.533186 11.4999 0.321615 11.9345C0.110044 12.369 6.54637e-05 12.846 0 13.3293L0 14.0365L6.375 18.6418V6.37521H27.625V18.6418L34 14.0365V13.3293C34 12.3504 33.5498 11.4254 32.7788 10.8211Z" 
        fill={color}
      />
    </Svg>
  );
};

export default MessageSvg;
