export const FETCH_SHARED_FILES = 'FETCH_SHARED_FILES';
export const UPLOAD_SHARED_FILE = 'UPLOAD_SHARED_FILE';
export const DELETE_SHARED_FILE = 'DELETE_SHARED_FILE';
export const ERROR_SHARED_FILES = 'ERROR_SHARED_FILES';
export const LOADING_SHARED_FILES = 'LOADING_SHARED_FILES';

import { AppDispatch } from '../../store';
import * as filesCtrl from '../../../controllers/files.controller';
import { FileType } from '../../../types/file';

export const actionFetchSharedFiles = (fileType: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      // dispatch({ type: LOADING_SHARED_FILES });
      const data = await filesCtrl.fetchFiles(fileType);
      dispatch({
        type: FETCH_SHARED_FILES,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: ERROR_SHARED_FILES,
        payload: error
      });
    };
  };
};

export const actionUploadSharedFile = (file: FileType, platform: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: LOADING_SHARED_FILES });
      const data = await filesCtrl.uploadFile(file, platform);
      dispatch({
        type: UPLOAD_SHARED_FILE,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: ERROR_SHARED_FILES,
        payload: error
      });
    };
  };
};

export const actionDeleteSharedFile = (fileType: string, filePath: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: LOADING_SHARED_FILES });
      await filesCtrl.deleteFile(fileType, filePath);
      dispatch({
        type: DELETE_SHARED_FILE,
        payload: filePath
      });
    } catch (error) {
      dispatch({
        type: ERROR_SHARED_FILES,
        payload: error
      });
    };
  };
};
