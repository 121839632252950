import { useState } from 'react';
import { getDocumentAsync }from 'expo-document-picker';
import { FileType } from '../types/file';
import { getBlobFromDataUri } from '../lib/files';

const useFile = (fileType: string) => {
  const [ file, setFile ] = useState<FileType>();
  const pickFile = async () => {
    await getDocumentAsync()
      .then(response => {
        if (response.type == 'success') {          
          const { name, size, uri } = response;
          const nameParts = name.split('.');
          const type = nameParts[nameParts.length - 1];
          const fileDef = {
            name: name,
            size: size,
            uri: uri,
            type: 'application/' + type,
            file_type: fileType
          };
          const fileBlob = getBlobFromDataUri(uri);
          setFile({
            fileDef, 
            fileBlob
          });
        };
      });
  };
  return {
    pickFile,
    file,
    setFile
  };
};

export default useFile;
