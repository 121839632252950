import React from 'react';
import { View } from 'react-native';
import tw from 'tailwind-react-native-classnames';
import { TextContent, TextSubTitle, TextTitle } from '../../../shared/controls/text-v1';
import Card from '../../../shared/controls/card-v1';
import { SectionPaymentDetailsProps } from './types';

const SectionPaymentDetails = (props: SectionPaymentDetailsProps) => {
  const { tenancy } = props;
  return (
    <Card>
      <View style={tw.style('w-full')}>
        <TextTitle title='Payment Details' />
      </View>
      <View style={tw.style('w-full flex-row mt-2')}>
        <View style={tw.style('w-1/2')}>
          <TextSubTitle title='Name:' />
          <TextContent title={tenancy.account_holder_name} />
          <TextSubTitle title='Bank:' />
          <TextContent title={tenancy.account_bank} />
          <TextSubTitle title='Account Number:' />
          <TextContent title={tenancy.account_number} />
          <TextSubTitle title='Sort Code:' />
          <TextContent title={tenancy.account_sort_code}/>
        </View>
      </View>
    </Card>
  );
};

export default SectionPaymentDetails;
