import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import Modal from 'react-native-modal';
import tw from 'tailwind-react-native-classnames';
import { ModalProps } from './types';
import Button from '../button-v1';
import { TextTitle, TextSubTitle } from '../text-v1';
import { TimesSvg } from '../../svg/icons';

const CustomModal = (props: ModalProps) => {
  const { 
    isOpen, 
    actionClose, 
    title, 
    subTitle, 
    textActionOne, 
    actionOne, 
    loadingActionOne = false,
    textActionTwo, 
    actionTwo,
    loadingActionTwo = false,
  } = props;

  const handleActionOne = async () => {
    await actionOne();
    actionClose();
  };

  return (
    <Modal
      isVisible={isOpen}
      onBackdropPress={() => actionClose()}
      animationInTiming={500}
      animationOutTiming={500}
    >
      <View style={tw.style('w-full bg-white items-center justify-center rounded-lg p-3 shadow web:shadow-md')}>
        <TouchableOpacity 
          style={tw.style('absolute top-1 right-1')}
          onPress={() => actionClose()}
        >
          <TimesSvg />
        </TouchableOpacity>
        <TextTitle title={title} />
        <TextSubTitle title={subTitle} />
        <View style={tw.style('self-stretch my-2')}>
          <Button title={textActionOne} loading={loadingActionOne} onPress={handleActionOne} />
        </View>
        <View style={tw.style('self-stretch')}>
          {actionTwo && textActionTwo && <Button title={textActionTwo} loading={loadingActionTwo} onPress={actionTwo} />}
        </View>
      </View>
    </Modal>
    
  );
};

export default CustomModal;
