import React from 'react';
import { View } from 'react-native';
import tw from 'tailwind-react-native-classnames';
import { TextContent, TextSubTitle, TextTitle } from '../../../shared/controls/text-v1';
import Card from '../../../shared/controls/card-v1';
import { SectionTenancyDetailsProps } from './types';
import moment from 'moment';

const SectionTenancyDetails = (props: SectionTenancyDetailsProps) => {
  const { tenancy } = props;
  return (
    <Card styleAdded='web: mt-2 ml-0'>
      <View style={tw.style('w-full')}>
        <TextTitle title='Tenancy Details' />
      </View>
      <View style={tw.style('w-full flex-row mt-2')}>
        <View style={tw.style('w-1/2')}>
          <TextSubTitle title='Property Address:' />
          <TextContent title={tenancy.address_1} />
          <TextContent title={tenancy.address_2} />
          <TextContent title={tenancy.postcode} />
          <TextSubTitle title='Start Date:' />
          <TextContent title={moment(tenancy.start_date).format('DD/MM/YYYY')} />
          <TextSubTitle title='End Date:' />
          <TextContent title={moment(tenancy.end_date).format('DD/MM/YYYY')} />
        </View>
        <View style={tw.style('border-l border-gray-300 w-1/2 pl-5')}>
          <TextSubTitle title='Letting Contact:' />
          <TextContent title={tenancy.letting_contact_fullname} />
          <TextSubTitle title='Emergency Contact:' />
          <TextContent title={tenancy.emergency_contact_fullname} />
          <TextSubTitle title='Email:' />
          <TextContent title={tenancy.emergency_contact_email} />
          <TextSubTitle title='Phone:' />
          <TextContent title={tenancy.emergency_contact_number} />
        </View>
      </View>
    </Card>
  );
};

export default SectionTenancyDetails;
