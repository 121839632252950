import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { SvgProps } from '../../types';

const AgreementSvg = ({ color='white', width=34, height=34 }: SvgProps) => {
  return (
    <Svg 
      width={width} 
      height={height}
      viewBox="0 0 25 32" 
    >
      <Path 
        d="M14.7332 8.5V0H1.95545C1.10573 0 0.422119 0.66875 0.422119 1.5V30.5C0.422119 31.3312 1.10573 32 1.95545 32H23.4221C24.2718 32 24.9554 31.3312 24.9554 30.5V10H16.2666C15.4232 10 14.7332 9.325 14.7332 8.5ZM18.8221 23.25C18.8221 23.6625 18.4771 24 18.0554 24H7.32212C6.90045 24 6.55545 23.6625 6.55545 23.25V22.75C6.55545 22.3375 6.90045 22 7.32212 22H18.0554C18.4771 22 18.8221 22.3375 18.8221 22.75V23.25ZM18.8221 19.25C18.8221 19.6625 18.4771 20 18.0554 20H7.32212C6.90045 20 6.55545 19.6625 6.55545 19.25V18.75C6.55545 18.3375 6.90045 18 7.32212 18H18.0554C18.4771 18 18.8221 18.3375 18.8221 18.75V19.25ZM18.8221 14.75V15.25C18.8221 15.6625 18.4771 16 18.0554 16H7.32212C6.90045 16 6.55545 15.6625 6.55545 15.25V14.75C6.55545 14.3375 6.90045 14 7.32212 14H18.0554C18.4771 14 18.8221 14.3375 18.8221 14.75ZM24.9554 7.61875V8H16.7777V0H17.1674C17.5763 0 17.966 0.15625 18.2535 0.4375L24.5082 6.5625C24.7957 6.84375 24.9554 7.225 24.9554 7.61875Z" 
        fill={color}
      />
    </Svg>
  );
};

export default AgreementSvg;
