import axios from 'axios';
import { Credential } from '../types/credential';
import { getTokenFromStorage } from '../lib/auth';
import { getUriByEnviroment } from '../lib/environment';
const URI = getUriByEnviroment();

export const getUserData = async () => {
  try {
    const res = await axios({
      method: 'GET',
      url: `${URI}/api/user/me`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${await getTokenFromStorage()}`
      },
    });
    if (res.data) {
      return res.data;
    } else {
      throw '😰 Sorry looks like something bad happened on our side!';
    };
  } catch (error: any) {
    throw `😰 ${error?.response?.data || error.message}`;    
  };
};

export const getToken = async (credentials: Credential) => {
  try {
    const res = await axios({
      method: 'POST',
      url: `${URI}/api/auth/login`,
      data: credentials,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (res.data) {
      return res.data;
    } else {
      throw '😰 Sorry looks like something bad happened on our side!';
    };
  } catch (error: any) {
    throw `😰 ${error?.response?.data || error.message}`;    
  };
};
