import React from 'react';
import DashboardStackNavigation from '../dashboard-stack-navigation';
import SettingsScreen from '../../screens/settings';
import { createDrawerNavigator } from '@react-navigation/drawer';
import 'react-native-gesture-handler';
import CustomDrawer from '../../components/navigation/custom-drawer';
import { BarsSvg } from '../../components/shared/svg/icons';
import tw from 'tailwind-react-native-classnames';
import { TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/core';
import { DrawerActions } from '@react-navigation/native';
import { TextTitle } from '../../components/shared/controls/text-v1';
import { actionSetLogout } from '../../redux/user/actions';
import { useDispatch } from 'react-redux';

const getOptions = (headerTitle: string, title: string) => {
  const navigation = useNavigation();
  const handlePressHamburger = () => {
    navigation.dispatch(DrawerActions.toggleDrawer());
  };
  const dispatch = useDispatch();
  const handlePressLogOut = () => {
    dispatch(actionSetLogout());
  };
  return (
    {
      title: title,
      headerShown: true,
      headerTitleAlign: 'center',
      headerTitle: headerTitle,
      headerStyle: { height: 90 },
      headerTintColor: '#596778',
      headerLeft: () => (
        <TouchableOpacity 
          style={tw.style('ml-3')}
          onPress={() => handlePressHamburger()}
        >
          <BarsSvg />
        </TouchableOpacity>
      ),
      headerRight: () => (
        <TouchableOpacity 
          style={tw.style('mr-3')}
          onPress={() => handlePressLogOut()}
        >
          <TextTitle title='Log Out' />
        </TouchableOpacity>
      )
    }
  );
};

const AppDrawerNavigation = () => {
  const Drawer = createDrawerNavigator();
  return(
    <Drawer.Navigator 
      initialRouteName="Dashboard"
      drawerContent={() => <CustomDrawer />}
    >
      <Drawer.Screen
        name="Dashboard Stack" 
        component={DashboardStackNavigation}
        options={getOptions('', 'Dashboard')}
      />
      <Drawer.Screen 
        name="Settings" 
        component={SettingsScreen} 
        options={getOptions('', 'Settings')}
      />
    </Drawer.Navigator>
  );
};

export default AppDrawerNavigation;
